.text-link {
  color: inherit;
  text-decoration: inherit;
}
.main{
  margin: 30px;
}

.main-mobile{
  margin: 0px;
}

@font-face {
  font-family: 'Kallisto';
  font-weight: normal;
  src: local('Kallisto'), url(./assets/fonts/kallisto_light.otf) format('opentype');
}
@font-face {
  font-family: 'Kallisto';
  font-weight: bold;
  src: local('Kallisto'), url(./assets/fonts/kallisto_bold.otf) format('opentype');
}
body{
  font-family: 'Inter', cursive;
  color: grey;
}
.currencyInput{
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  box-sizing: content-box;
  height: 0.44rem;
  margin: 0;
  min-width: 0;
  border-style: solid;
  width: 100%;
  padding: 16.5px 14px;
}
.currencyInput:focus-visible{
  outline: none;
}

.currencyInputBlack{
    font: inherit;
    letter-spacing: inherit;
    color: #ffffff;
    box-sizing: content-box;
    height: 0.44rem;
    margin: 0;
    min-width: 0;
    width: 100%;
    padding: 16.5px 14px;
    background-color: #242831;
}
.currencyInputBlack:focus-visible{
    outline: none;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.9);
}

.tour{
  background-color: #31313C !important;
  color: #D8D8D8 !important;
}

.table-container{
  max-height: calc(100vh - 132px - 40px - 110px);
}

.button-mobile{
  font-size: 10px;
}

.negative-value {
  color: #E53E3E; /* ou use a constante RED_TABLE */
}

@media screen and (max-width: 1400px) {
  .table-container{
    max-height: calc(100vh - 132px - 40px - 110px);
  }
}

@media screen and (max-width: 930px) {
  .table-container{
    max-height: calc(100vh - 132px - 40px - 180px);
  }
}

@media print {
    .table-container{
        max-height: 100% !important;
    }
}
