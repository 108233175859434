.MuiListItemIcon-root{
    min-width: 45px !important;
}

.container-list-subitens{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}

.style_icon_subitens{
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: #ECC94B;
}
