.MuiPaper-root{
    overflow-x: hidden;
}

@keyframes append-animate {
    from {
        transform: translateX(-200%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes close-animate {
    from {
        transform: translateX(200%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.bm-burger-button{
    display: none;
}

.bm-menu {
    padding: 2.5em 1.5em 0;
    margin-top: 74px;
    overflow: auto;
}
