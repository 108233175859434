.table-comment{
    position: relative;
}

.table-fixed{
    position: relative;
}

.table-provisioned{
    position: relative;
}

.table-comment::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0 ;
    height: 0 ;
    display: block ;
    border-top-color: #9F7AEA !important;
    border-right-color: #9F7AEA;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
    border-width: 12px 12px 12px 12px;
}

.table-provisioned::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0 ;
    height: 0 ;
    display: block ;
    border-top-color: #4299E1;
    border-right-color: #4299E1 !important;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
    border-width: 12px 12px 12px 12px;
}

.table-fixed::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0 ;
    height: 0 ;
    display: block ;
    border-top-color: #ECC94B ;
    border-right-color: #ECC94B !important;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
    border-width: 12px 12px 12px 12px;
}

.info-text{
    color: #808080;
}
.info-class-text{
    color: #444444;
    font-size: 17px;
}

.main-modal{
    margin-bottom: 20px;
}

.modal{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50vh;
    padding: 5px;
}

.nav-plano-contas{
    height: calc(100vh - 64px - 60px);
    overflow-y: auto;
}

.table-row{
    animation: append-row 0.2s ease-out;
}

.table-row-data{
    animation: append-row 0.2s ease-out;
}

@keyframes append-row {
    from {
        transform: translateY(-10%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}