/* Estilizando o título do calendário */
.fc-toolbar-title {
    font-size: 22px !important;
    color: #4A5568;
}

.fc-toolbar-title::first-letter {
    text-transform: uppercase;
}

/* Estilizando o botão de passar os meses */
.fc-prev-button, .fc-next-button {
    background-color: #ECC94B !important;
    border-color: #ECC94B !important;
}

.fc-icon-chevron-right, .fc-icon-chevron-left, .fc-icon-chevron-right::before, .fc-icon-chevron-left::before {
    color: #4A5568 !important;
}

.fc-button-group {
    font-size: 70% !important;
}
