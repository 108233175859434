.button-save{
    top: 1%;
}
.button-multi{
    top: 15%;
    background-color: #000000;
    color: #ffffff;
}
.text-modal{
    color: rgba(0, 0, 0, 0.7);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}
.start{
    text-align: start;
}
.relative{
    position: relative;
}

.divCurrencyInput{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    padding: 0;
    margin: 0;
    vertical-align: top;

    border-color: rgb(179, 179, 179);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    border-color: rgba(0, 0, 0, 0.23);
}

.currencyInput{
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0;
    box-sizing: content-box;
    height: 1.4375em;
    margin: 0;
    min-width: 0;
    width: 100%;
    padding: 16.5px 14px;
}
.obs{
    width: 100%;
}